import http from './http'

class Swap {
  fetch(payload) {
    return http.get(`/admin/swaps/`, { params: payload })
  }

  create(payload) {
    return http.post(`/admin/swaps/`, { swap: payload })
  }

  update(payload) {
    return http.put(`/admin/swaps/${payload.id}`, { swap: payload })
  }

  get(id) {
    return http.get(`/admin/swaps/${id}`)
  }

  delete(id) {
    return http.delete(`/admin/swaps/${id}`)
  }
}

export default new Swap()