<template>
  <div>
    <b-card>
      <h2 class="mb-2">{{ !!swap.id ? 'Editar' : 'Cadastrar' }}</h2>
      <validation-observer ref="changePasswordForm" #default="{invalid}">
        <b-form class="">
          <b-row>
            <b-col>
              <b-input-group label="Data inicial">
                <validation-provider #default="{ errors }" name="Data inicial" vid="data_inicial" rules="required" class="w-100">
                  <span class="">Data inicial</span>
                  <flat-pickr
                    v-model="swap.data_inicio"
                    class="form-control bg-white mr-1 assignor-selector"
                    :config="datePicker"
                    placeholder="Data Inicial"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group label="Data final">
                <validation-provider #default="{ errors }" name="Data final" vid="data_final" rules="required" class="w-100">
                  <span class="">Data final</span>
                  <flat-pickr
                    v-model="swap.data_fim"
                    class="form-control bg-white mr-1 assignor-selector"
                    :config="datePicker"
                    placeholder="Data Inicial"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group label="Cliente Ativo">
                <validation-provider #default="{ errors }" name="Cliente Ativo" vid="cliente_ativo" rules="required" class="w-100">
                  <span class="">Cliente Ativo</span>
                  <money
                    id="cliente_ativo"  
                    v-model="swap.cliente_ativo"
                    class="form-control"
                    name="cliente_ativo"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    v-bind="percentage"
                    :maxlength="8"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group label="Cliente Passivo">
                <validation-provider #default="{ errors }" name="Cliente Passivo" vid="cliente_passivo" rules="required" class="w-100">
                  <span class="">Cliente Passivo</span>
                  <money
                    id="cliente_passivo"
                    v-model="swap.cliente_passivo"
                    class="form-control"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    v-bind="percentage4"
                    name="cliente_passivo"
                    min="0"
                    :maxlength="16"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-input-group>
            </b-col>
          </b-row>
          <div class="d-flex w-100 justify-content-end mt-2">
            <b-button variant="primary" @click="$router.push({ name: 'swap.index' })" class="mr-1">
              <span class="mr-25 align-middle">Voltar</span>
            </b-button>
            <b-button variant="secondary" @click="saveSwap" :disabled="invalid">{{ !!swap.id ? 'Salvar' : 'Cadastrar' }}</b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>

</template>

<script>
import swap from '@/services/swap'
import { BAlert, BBadge, BCard, BCol, BRow, BButton, BForm, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, } from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

export default {
  name: 'ShowSwap',
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider, ValidationObserver,
    flatPickr
  },
  data() {
    return {
      required,
      swap: {
        id: '',
        cliente_ativo: '',
        cliente_passivo: '',
        data_inicio: '',
        data_fim: '',
      },
      percentage: {
        thousands: '.',
        prefix: '',
        suffix: ' %',
        precision: 2,
      },
      percentage4: {
        thousands: '.',
        prefix: '',
        suffix: ' %',
        precision: 4,
      },
      datePicker: {
        locale: Portuguese,
        dateFormat: 'Y-m-d',
        altInput: true,
        altFormat: 'd/m/Y',
      },
    }
  },
  async mounted() {
    await this.fetchSwap()
  },
  methods: {
    async fetchSwap() {
      if (!this.$route.params.id) return
      try {
        const { data } = await swap.get(this.$route.params.id)
        this.swap = data
      } catch (error) {
        this.$swal.fire({
          title: 'Erro!',
          text: 'Erro ao buscar informações do swap!',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },
    async saveSwap() {
      if (!!this.swap.id) {
        await this.updateSwap()
      } else {
        await this.createSwap()
      }
    },
    async updateSwap() {
      try {
        await swap.update(this.swap)
        this.$router.push({ name: 'swap.index' })
        this.$swal.fire({
          title: 'Sucesso!',
          text: 'Swap atualizado com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (error) {
        // console.log(error)
      }
    },
    async createSwap() {
      try {
        const data = await swap.create(this.swap)
        this.$router.push({ name: 'swap.index' })
        this.$swal.fire({
          title: 'Sucesso!',
          text: 'Swap cadastrado com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
          timer: 2000,
          timerProgressBar: true,
        })
      } catch (error) {
        // console.log(error)
      }
    },
    updateClienteAtivo(value) {
      // Aqui você pode processar o valor se necessário
      console.log(value);
      this.cliente_ativo = parseFloat(value.replaceAll('.', '').replace(',', '.'));  // Atualiza o v-model com o valor formatado
    },
  },
}
</script>
<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>